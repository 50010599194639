import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { localize } from '../helpers/helpers'

import SEO from '../components/seo'
import Hero from '../components/home/hero'
import Galleries from '../components/home/galleries'
import CTA from '../components/cta'

const IndexPage = ({ data, pageContext, location }) => {
  const
    info = localize(data.sanityHome, [pageContext.language]),
    { title, leftGallery, rightGallery, endGallery, endTitle, _rawDescription, _rawSmallText, _rawText, vimeoLink } = info,
    { t } = useTranslation('common');

  useEffect(() => {
    const wrapper = document.querySelector('#gatsby-focus-wrapper')
    wrapper.style.backgroundColor = "#F0EFED"
  })

  return (
    <>
      <SEO title={t('home')} lang={pageContext.language} location={location} />
      <Hero title={title} video={vimeoLink} />
      <Galleries
        text={_rawText}
        title={endTitle}
        smallText={_rawSmallText}
        left={leftGallery.gallery}
        gallery={endGallery.gallery}
        right={rightGallery.gallery}
        description={_rawDescription}/>
      <CTA title="portfolio" />
    </>
  )
}

export const query = graphql`
  query Homepage($language: String) {
    sanityHome {
      title {
        translate(language: $language)
      }
      vimeoLink
      leftGallery {
        gallery {
          asset {
            fluid(maxWidth: 980) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
      }
      rightGallery {
        gallery {
          asset {
            fluid(maxWidth: 980) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
      }
      endGallery {
        gallery {
          asset {
            fluid(maxWidth: 2000) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
      }
      endTitle {
        translate(language: $language)
      }
      _rawDescription
      _rawSmallText
      _rawText
    }
  }
`

export default IndexPage
